<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>库房管理</el-breadcrumb-item>
        <el-breadcrumb-item>库房信息列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>

    <el-main class="p15 pt0">

      <div class="pt20" style="text-align:left;">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
             <el-input
                placeholder="库房名称"
                v-model="page.warehouse_name"
                clearable
                @clear="onSubmit(page.warehouse_name)"
              >
              </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
                v-model="page.nature"
                clearable
                placeholder="性质"
                @change="onSubmit(page.nature)"
              >
                <el-option
                  v-for="(item, index) in natureOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
          </el-form-item>
          <el-form-item>
             <el-button type="primary" @click="onSubmit" size="medium"
                >查询</el-button
              >
              <el-button type="primary" size="medium" @click="Add"
                >新增库房</el-button
              >
          </el-form-item>
        </el-form>
      </div>

      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            fixed
            type="expand"
            align="center"
            width="100"
            label="详细信息"
          >
            <template slot-scope="props">
              <div style="padding: 0 20px">
                <el-descriptions>
                  <el-descriptions-item label="面积"
                    ><span>{{ props.row.warehouse_area }}</span>
                    m²</el-descriptions-item
                  >
                  <el-descriptions-item label="容积"
                    ><span>{{ props.row.warehouse_volume }}</span>
                    m³</el-descriptions-item
                  >
                  <el-descriptions-item label="储存环境">{{
                    props.row.storage_conditions_names
                  }}</el-descriptions-item>
                  <el-descriptions-item label="地址">{{
                    props.row.address
                  }}</el-descriptions-item>
                  <el-descriptions-item label="添加时间">{{
                    props.row.add_time
                  }}</el-descriptions-item>
                </el-descriptions>
              </div>

              <!-- <el-descriptions
                class="margin-top"
                :column="3"
                :size="size"
                border
              >
                <el-descriptions-item>
                  <template slot="label"> 面积 </template>
                  <span>{{ props.row.warehouse_area }}</span> m²
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 容积 </template>
                  <span>{{ props.row.warehouse_volume }}</span> m³
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 储存环境 </template>
                  <span>{{ props.row.storage_conditions_names }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    地址
                  </template>
                  <span>{{ props.row.address }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 添加时间 </template>
                  <span>{{ props.row.add_time }}</span>
                </el-descriptions-item>
              </el-descriptions> -->

              <!-- <el-form label-position="left" class="demo-table-expand">
                <el-form-item label="面积：">
                  <span>{{ props.row.warehouse_area }}</span> m²
                </el-form-item>
                <el-form-item label="容积：">
                  <span>{{ props.row.warehouse_volume }}</span> m³
                </el-form-item>
                <el-form-item label="储存环境：">
                  <span>{{ props.row.storage_conditions_names }}</span>
                </el-form-item>
                <el-form-item label="地址：">
                  <span>{{ props.row.address }}</span>
                </el-form-item>
                <el-form-item label="添加时间：">
                  <span>{{ props.row.add_time }}</span>
                </el-form-item>
              </el-form> -->
            </template>
          </el-table-column>

          <el-table-column
            prop="warehouse_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="库房名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="nature"
            min-width="120"
            :show-overflow-tooltip="true"
            label="场地性质"
            align="center"
          >
            <template scope="props">
              <span v-if="props.row.nature === '自有'" style="color: green">{{
                props.row.nature
              }}</span>
              <span v-else style="color: red">{{ props.row.nature }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="warehouse_principal"
            min-width="80"
            :show-overflow-tooltip="true"
            label="负责人"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="warehouse_mobile"
            min-width="120"
            :show-overflow-tooltip="true"
            label="联系电话"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="company_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="公司名字"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="credit_code"
            min-width="160"
            :show-overflow-tooltip="true"
            label="统一社会信用代码"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="supervise_type"
            min-width="90"
            :show-overflow-tooltip="true"
            label="监管仓"
            align="center"
          >
            <template scope="scope">
              <span style="color: green" v-if="scope.row.supervise_type == 1"
                >是</span
              >
              <span style="color: red" v-else-if="scope.row.supervise_type == 2"
                >否</span
              >
              <span style="color: blue" v-else>Unknown</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="goUpdate(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="openGoodsDialog(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog title="库房详情" :visible.sync="dialogVisible">
        <el-row class="box search mt20">
          <el-form :inline="true" :model="goodsPage" class="demo-form-inline">
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="商品名、商品编码"
                  v-model="goodsPage.keyword"
                  clearable
                  @clear="searchGoods"
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6" class="tl mr10">
              <el-form-item size="medium">
                <div class="df">
                  <el-button
                    type="primary"
                    @click="searchGoods"
                    class="general_bgc general_border cfff"
                    >查询</el-button
                  >
                </div>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-table border :data="goodsList" style="width: 100%" id="out-table">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="gtin"
            min-width="120"
            :show-overflow-tooltip="true"
            label="商品编码"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="goodsName"
            label="商品名"
            min-width="120"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="stock"
            min-width="120"
            :show-overflow-tooltip="true"
            label="库存(kg)"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="num"
            min-width="120"
            :show-overflow-tooltip="true"
            label="数量"
            align="center"
          ></el-table-column>
        </el-table>
        <div class="i-page disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="goodsTotal"
            :current-page="goodsCurrentPage"
            @current-change="goodsCurrentChange"
          >
          </el-pagination>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import axios from "@/util/api";
import requestApi from "@/util/extra-api";

import { local } from "@/util/util";
import { mapState } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      goodsTotal: 0,
      goodsCurrentPage: 1,
      goodsList: [],
      user: "",
      page: {
        warehouse_name: "", //仓库的名称
        nature: "", //性质
        pager_offset: "0",
        pager_openset: "10",
      },
      goodsPage: {
        page: 1,
        limit: 10,
        keyword: "",
        warehouseId: "",
        companyId: "",
      },
      timer: "",
      loading: false,
      natureOptions: [
        { label: "全部", value: "" },
        { label: "自建", value: "自建" },
        { label: "租用", value: "租用" },
      ],
      currentPage: 1,
      total: 0,
      tableData: [],
      activeName: "1",
      company_id: "",
      url: "",
      showViewer: false,
    };
  },
  filters: {},
  computed: {
    ...mapState({
      carState: (state) => state.carManage.carState,
    }),
  },
  mounted() {
    this.page = Object.assign({}, this.page);
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
    this.goodsPage.companyId = String(local.get("company_id"));
  },
  methods: {
    goodsInit(page) {
      requestApi.get("/v1/pc/stock/warehouseStock", page).then((res) => {
        if (res.code === 200) {
          // console.log(res);
          this.goodsList = res.data.map((v) => {
            let num = "";
            if (v.stock !== 0) {
              if (v.stockUnits && v.stockUnits) {
                v.stockUnits.forEach((item, i) => {
                  num += item.unitNum + item.unitName;
                  if (i !== v.stockUnits.length - 1) {
                    num += " + ";
                  }
                });
              } else {
                num = "无单位";
              }
            } else {
              num = "无";
            }

            return {
              ...v,
              num,
            };
          });
          this.goodsTotal = res.total;
        }
      });
    },
    goodsCurrentChange(page) {
      this.goodsCurrentPage = page;
      this.goodsPage.page = page;
      this.goodsInit(this.goodsPage);
    },
    searchGoods() {
      this.goodsCurrentPage = 1;
      this.goodsPage.page = 1;
      this.goodsInit(this.goodsPage);
    },
    openGoodsDialog(row) {
      this.goodsPage.warehouseId = row.id;
      this.goodsPage.page = 1;
      this.goodsCurrentPage = 1;
      this.dialogVisible = true;
      this.goodsInit(this.goodsPage);
    },
    init(params) {
      axios.get("/pc/company-warehouse/list", params).then((v) => {
        this.tableData = v.data.company_list;
        this.total = v.data.pager_count;
        //拼接地址
        for (var i = 0; i < this.tableData.length; i++) {
          this.tableData[i].address =
            this.tableData[i].province +
            this.tableData[i].city +
            this.tableData[i].area +
            this.tableData[i].town +
            this.tableData[i].vill +
            this.tableData[i].address;
        }
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("warehouseManage/setNewPage", this.page);
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("warehouseManage/setNewPage", this.page);
      this.init(this.page);
    },
    //新增库房
    Add() {
      this.$router.push({ name: "WarehouseListAdd", query: { car_id: "" } });
    },
    //编辑
    goUpdate(row) {
      this.$router.push({
        name: "WarehouseListAdd",
        query: { warehouse_id: row.id, nature: row.nature },
      });
    },
    handleStateChange(val, row) {
      const { id } = row;
      let car_state = val + "";
      axios
        .put("/pc/company-car/state/update", { car_id: id + "", car_state })
        .then((response) => {
          this.init(this.page);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
